import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { fetchSimBasicInfo } from '../utils/fetchData'

class KeyParameter {
    constructor(key, value) {
        this.key = key
        this.value = value
    }
}


export default function KeyParameterTable({ simId }) {

    // ======= private states =======
    const [parameters, setParameters] = useState([])

    // column configuration for recommendation task table
    const columns = [
        {
            dataField: 'key',
            text: 'Key',
            sort: true
        },
        {
            dataField: 'value',
            text: 'Value',
            sort: true
        }
    ]

    // ======= component hooks ========
    useEffect(() => {

        if (!simId)
            return

        setParameters([])

        fetchSimBasicInfo(simId, (basicInfo) => {
            const newKeyParameters = []

            for (const [key, value] of Object.entries(basicInfo['parameters'])) {
                newKeyParameters.push(new KeyParameter(key, value))
            }

            setParameters(newKeyParameters)
        })

    }, [simId])

    return (
        <div>
            <h5>Key Parameters</h5>
            <hr/>
            <BootstrapTable
                keyField='key'
                hover={true}
                data={parameters}
                columns={columns}
                pagination={paginationFactory({
                    sizePerPageList: [20, 30]
                })}
                bordered={false}
                condensed={false}
                bootstrap4={true} />
        </div>
    )
}
