import React, { useEffect, useRef, useState } from 'react'
import { Table, Modal } from 'react-bootstrap'
import { fetchJson, fetchJsonFromPublicFolder, fetchTaskBasicInfo, fetchWorkerPublishingTasks } from '../utils/fetchData'
import TaskModal from './TaskModal'
import { toMostFixedDigits } from '../utils/mathUtils'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

class PublishingTask {
    constructor(taskId, start, end, workers, bidders, cost, budget, accuracy) {
        this.taskId = taskId
        this.start = start
        this.end = end
        this.workers = workers
        this.bidders = bidders
        this.cost = cost
        this.budget = budget
        this.accuracy = accuracy
    }
}


export default function WorkerPublishingTasksTable({ simId, workerId }) {
    const [tasks, setTasks] = useState([])
    const [currentTaskId, setCurrentTaskId] = useState(null)

    const showModalFuncRef = useRef(null)

    // column configuration for task table
    const columns = [
        {
            dataField: 'taskId',
            text: 'Task',
            sort: true,
            formatter: (cell, row) => `Task ${cell}`,
            classes: 'task',
            attrs: function callback(cell, row, rowIndex, colIndex) {
                return { 'data-id': cell }
            },
            events: {
                onClick: (evt, column, columnIndex, row, rowIndex) => {
                    console.log(evt.target)
                    setCurrentTaskId(evt.target.dataset['id'])
                    showModalFuncRef.current()
                }
            },
        }, {
            dataField: 'start',
            text: 'Start',
            sort: true
        },
        {
            dataField: 'end',
            text: 'End',
            sort: true
        },
        {
            dataField: 'cost',
            text: 'Cost',
            sort: true
        },
        {
            dataField: 'budget',
            text: 'Budget',
            sort: true
        },
        {
            dataField: 'workers',
            text: 'Workers',
            sort: true
        },
        {
            dataField: 'bidders',
            text: 'Bidders',
            sort: true
        },
        {
            dataField: 'accuracy',
            text: 'Accuracy',
            formatter: (cell, row) => toMostFixedDigits(cell)
        }
    ];

    useEffect(() => {

        // this line of code takes effect when there is no publishing task for this worker
        setTasks([])
        
        // initialize the container for publishing tasks
        const publishingTasks = []

        fetchWorkerPublishingTasks(simId, workerId, (rawPublishingTasks) => {
            rawPublishingTasks.forEach(taskId => {

                // task content
                fetchTaskBasicInfo(simId, taskId, (resTask) => {
                    publishingTasks.push(new PublishingTask(taskId,
                        resTask.startTime,
                        resTask.endTime,
                        resTask.workers,
                        resTask.bidders,
                        resTask.cost,
                        resTask.budget,
                        resTask.accuracy))

                    if (rawPublishingTasks.length === publishingTasks.length) {
                        setTasks(publishingTasks)
                    }
                })
            });
        })

    }, [simId, workerId])

    function handleTaskIdClick(evt) {
        console.log(evt.target)
        setCurrentTaskId(evt.target.innerText)
        showModalFuncRef.current()
    }

    return (
        <div className='task-table-container'>
            {
                tasks.length > 0 &&
                <div>
                    <h6>Publishing Tasks</h6>
                    <BootstrapTable
                        keyField='taskId'
                        data={tasks}
                        columns={columns}
                        pagination={paginationFactory({
                            sizePerPageList: [7, 10]
                        })}
                        bordered={false}
                        condensed={false}
                        bootstrap4={true}
                    />
                </div>
            }
            <TaskModal simId={simId} taskId={currentTaskId} showModalFuncRef={showModalFuncRef}></TaskModal>
        </div>
    )
}
