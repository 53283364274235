import React from 'react'
import { Line } from 'react-chartjs-2'
import { useEffect } from 'react';
import { useState } from 'react';
import { fetchJsonFromPublicFolder } from '../utils/fetchData';
import { createRangeArray } from '../utils/other';

export default function WorkerReputationsLineChart({ simId, workerId }) {

    const [data, setData] = useState({ labels: [], datasets: [] })

    useEffect(() => {

        fetchJsonFromPublicFolder('/output/' + simId + '/Workers/' + workerId + '/BasicInfo.json', (workerInfo) => {
            const newData =
            {
                labels: createRangeArray(workerInfo['bidReputationSeries'].length),
                datasets: [
                    {
                        data: workerInfo['bidReputationSeries'],
                        label: "Bidding Suc. Rate",
                        borderColor: "#893196",
                        fill: true,
                        // backgroundColor: "#8152c7",
                    },
                    {
                        data: workerInfo['trainReputationSeries'],
                        label: "Training Rep.",
                        borderColor: "#593196",
                        fill: true
                    },
                    {
                        data: workerInfo['recommendReputationSeries'],
                        label: "Referral Rep.",
                        borderColor: "#967e31",
                        fill: true
                    }
                ]
            }

            setData(newData)
        })


    }, [simId, workerId])

    return (
        <>
            <h6>Worker Reputations</h6>
            <Line
                data={data}
                options={
                    {
                        responsive: true,
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                            },
                            title: {
                                display: false,
                                text: 'Worker Reputations',
                            },

                        },
                        scales: {
                            xAxis: {
                                display: true
                            },
                            yAxis: {
                                display: true
                            }
                        },
                        elements: {
                            point: {
                                borderWidth: 1,
                                radius: 3,
                                // backgroundColor: 'rgba(0,0,0,0)'
                            }
                        }
                    }
                }
            />
        </>
    )
}
