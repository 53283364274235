import React, { useEffect, useState, useRef } from 'react'
import { Row, Table } from 'react-bootstrap'
import TaskModal from './TaskModal'
import { fetchJsonFromPublicFolder, fetchTaskBasicInfo, fetchWorkerBiddingTasks } from '../utils/fetchData'
import { toMostFixedDigits, toMostFixedDigitsOrHyphen, toFixedDigitsOrHyphen } from '../utils/mathUtils'
import { ColorLabel } from './ColorLabel'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

class BidTask {
    constructor(taskId, startTime, endTime, accuracy, trainer, bidReputationGain, income, contribution, trainReputationGain) {
        this.taskId = taskId
        this.startTime = startTime
        this.endTime = endTime
        this.accuracy = accuracy
        this.trainer = trainer
        this.bidReputationGain = bidReputationGain
        this.income = income
        this.contribution = contribution
        this.trainReputationGain = trainReputationGain
    }
}


export default function WorkerBiddingTasksTable({ simId, workerId }) {

    // bidding task list
    const [tasks, setTasks] = useState([])

    // current clicked task
    const [currentTaskId, setCurrentTaskId] = useState(null)

    // function to display the modal
    const showModalFuncRef = useRef(null)

    // columns configuration for bidding task table
    const columns = [{
        dataField: 'taskId',
        text: 'Task',
        sort: true,
        formatter: (cell, row) => `Task ${cell}`,
        classes: 'task',
        attrs: function callback(cell, row, rowIndex, colIndex) {
            return { 'data-id': cell }
        },
        events: {
            onClick: (evt, column, columnIndex, row, rowIndex) => {
                console.log(evt.target)
                setCurrentTaskId(evt.target.dataset['id'])
                showModalFuncRef.current()
            }
        },
    }, {
        dataField: 'startTime',
        text: 'Start',
        sort: true
    }, {
        dataField: 'endTime',
        text: 'End'
    }, {
        dataField: 'accuracy',
        text: 'Accuracy',
        formatter: (cell, row) => toMostFixedDigits(cell)
    },
    {
        dataField: 'trainer',
        hidden: true
    },
    {
        dataField: 'bidReputationGain',
        headerFormatter: () => <abbr title="Bidding Success Ratio Gain">BSRG</abbr>,
        formatter: (cell, row) => <ColorLabel num={cell} ></ColorLabel>,
        sort: true,
        text: 'Bidding Reputation Gain'
    },
    {
        dataField: 'income',
        headerFormatter: () => <abbr title="Income">INCM</abbr>,
        formatter: (cell, row) => toMostFixedDigitsOrHyphen(cell),
        sort: true,
        text: 'Income'
    }, {
        dataField: 'contribution',
        headerFormatter: () => <abbr title="Contribution">CONTB</abbr>,
        formatter: (cell, row) => toMostFixedDigitsOrHyphen(cell),
        sort: true,
        text: 'Contribution'
    }, {
        dataField: 'trainReputationGain',
        headerFormatter: () => <abbr title="Training Reputation Gain">TRG</abbr>,
        formatter: (cell, row) => <ColorLabel num={cell} ></ColorLabel>,
        sort: true,
        text: "Training Reputation Gain"
    }
    ];

    useEffect(() => {

        setTasks([])

        // container to store new bidding task list
        const newBidTasksList = []

        fetchWorkerBiddingTasks(simId, workerId, (bidTaskContents) => {
            bidTaskContents.forEach((bidTaskContent) => {
                fetchTaskBasicInfo(simId, bidTaskContent.taskId, (resTask) => {
                    newBidTasksList.push(new BidTask(bidTaskContent.taskId, resTask.startTime, resTask.endTime, resTask.accuracy,
                        bidTaskContent.trainer, bidTaskContent.bidReputationGain, bidTaskContent.income, bidTaskContent.contribution, bidTaskContent.trainReputationGain))

                    if (newBidTasksList.length === bidTaskContents.length) {
                        setTasks(newBidTasksList)
                    }
                })
            })
        })


    }, [simId, workerId])

    return (
        <div className='task-table-container'>
            {
                tasks.length>0 &&
                <div>
                    <h6>Worker Bidding Tasks</h6>
                    <BootstrapTable
                        keyField='taskId'
                        data={tasks}
                        columns={columns}
                        pagination={paginationFactory({
                            sizePerPageList: [7, 10]
                        })}
                        bordered={false}
                        condensed={false}
                        bootstrap4={true}
                        rowClasses={(row, rowIndex) => {
                            return row['trainer'] ? 'success-left-border' : 'danger-left-border'
                        }}
                        classes="left-border-table" />

                    <TaskModal simId={simId} taskId={currentTaskId} showModalFuncRef={showModalFuncRef}></TaskModal>
                </div>
            }
        </div>
    )
}
