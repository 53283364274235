

export function fetchJson(url, callback, printLog = false) {
    fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }).then(function (response) {
        if (printLog) {
            console.log(`response of ${url}`)
            console.log(response)
        }

        return response.json();
    })
        .then(function (jsonContent) {
            if (printLog) {
                console.log(`json content of ${url}`)
                console.log(jsonContent);
            }

            callback(jsonContent)
        });
}

export function fetchJsonFromPublicFolder(relativePath, callback, printLog = false) {

    const fullPath = process.env.PUBLIC_URL + relativePath

    fetchJson(fullPath, callback, printLog)
}

export function fetchSimBasicInfo(simId, callback) {

    fetchJsonFromPublicFolder('/output/' + simId + '/BasicInfo.json', (basicInfo) => {
        callback(basicInfo)
    })
}

export function fetchSimNetwork(simId, callback) {

    fetchJsonFromPublicFolder('/output/' + simId + '/Network.json', (networkElements) => {
        callback(networkElements)
    })
}

export function fetchSimTimeline(simId, callback) {

    fetchJsonFromPublicFolder('/output/' + simId + '/Timeline.json', (timeline) => {
        callback(timeline)
    })
}

export function fetchWorkerBasicInfo(simId, workerId, callback) {

    fetchJsonFromPublicFolder('/output/' + simId + '/Workers/' + workerId + '/BasicInfo.json', (workerInfo) => {
        callback(workerInfo)
    })
}

export function fetchWorkerPublishingTasks(simId, workerId, callback) {

    fetchJsonFromPublicFolder('/output/' + simId + '/Workers/' + workerId + '/PublishingTasks.json', (workerInfo) => {
        callback(workerInfo)
    })
}

export function fetchWorkerBiddingTasks(simId, workerId, callback) {

    fetchJsonFromPublicFolder('/output/' + simId + '/Workers/' + workerId + '/BiddingTasks.json', (workerInfo) => {
        callback(workerInfo)
    })
}

export function fetchWorkerRecommendationTasks(simId, workerId, callback) {

    fetchJsonFromPublicFolder('/output/' + simId + '/Workers/' + workerId + '/RecommendationTasks.json', (workerInfo) => {
        callback(workerInfo)
    })
}

export function fetchTaskBasicInfo(simId, taskId, callback) {
    fetchJsonFromPublicFolder('/output/' + simId + '/Tasks/' + taskId + '/BasicInfo.json', (taskInfo) => {
        callback(taskInfo)
    })
}

export function fetchTaskNetwork(simId, taskId, callback) {
    fetchJsonFromPublicFolder('/output/' + simId + '/Tasks/' + taskId + '/Network.json', (network) => {
        callback(network)
    })
}

export function fetchTaskInvolvedAgents(simId, taskId, callback) {
    fetchJsonFromPublicFolder('/output/' + simId + '/Tasks/' + taskId + '/InvolvedAgents.json', (agents) => {
        callback(agents)
    })
}