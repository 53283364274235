import React, { useRef, useEffect, useState } from 'react'
import Plotly from 'plotly.js-dist-min'
import { fetchJsonFromPublicFolder } from '../utils/fetchData'
import TaskModal from './TaskModal'

export default function Timeline({ simId }) {

    const [currentTaskId, setCurrentTaskId] = useState(null)

    const timelineRef = useRef(null)

    const showModalFuncRef = useRef(null)

    useEffect(() => {

        var data = [
            {
                z: [[1, null, 30, 50, 1], [null, 1, 60, 80, 30], [30, 60, 1, -10, 20]],
                x: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
                y: ['Morning', 'Afternoon', 'Evening'],
                type: 'heatmap',
                hoverongaps: false,
                showscale: false
            }
        ];

        const dataRelPath = '/output/' + simId + '/Timeline.json'

        fetchJsonFromPublicFolder(dataRelPath, (returnData) => {

            const d = []

            // https://plotly.com/javascript/reference/heatmap/#heatmap-xgap

            returnData['type'] = 'heatmap'
            // returnData['hoverongaps'] = false
            returnData['showscale'] = false
            returnData['xgap'] = 0
            returnData['ygap'] = 1
            returnData['hovertemplate'] =
                '<i>worker</i>: %{y}' +
                '<br><b>time</b>: %{x}<br>'+
                'task: %{z}'

            d.push(returnData)

            Plotly.newPlot(timelineRef.current, d,
                {
                    xaxis: {
                        rangeslider: { visible: true },
                    },
                    height: Math.max(returnData.z.length * 22, 450),
                    // margin: {
                    //     l: 50,
                    //     r: 50,
                    //     b: 100,
                    //     t: 100,
                    //     pad: 4
                    // },
                    // paper_bgcolor: '#7f7f7f',
                    // plot_bgcolor: '#c7c7c7'
                }
            )

            timelineRef.current.on('plotly_click', function (data) {
                var pts = '';
                console.log('click')
                for (var i = 0; i < data.points.length; i++) {
                    console.log('x = ' + data.points[i].x +
                        'y = ' + data.points[i].y);
                }

                if (!data.points[0].z)
                    return

                setCurrentTaskId(data.points[0].z)
                showModalFuncRef.current()
            });
        })

    }, [simId])


    return (
        <>
            <h2>Timeline</h2>
            <hr />
            <div id="timeline-heatmap" ref={timelineRef}></div>
            <TaskModal simId={simId} taskId={currentTaskId} showModalFuncRef={showModalFuncRef}></TaskModal>
        </>
    )
}
