import React from "react";
import {toMostFixedDigitsOrHyphen} from '../utils/mathUtils'

/**
 * Returns a React label element due to given value
 * @param {number} num The value to be wrapped in <label>
 */
export function ColorLabel({ num }) {

    const props = {}

    let sign = ''

    if (!isNaN(num)) {
        if (num > 0){
            props['className'] = 'text-success'
            sign = '+'
        }
        if (num < 0){
            props['className'] = 'text-danger'
        }
    }

    return React.createElement('label', props, `${sign}${toMostFixedDigitsOrHyphen(num)}`)
}