import React from 'react'
import { Card, Row, Col, Container } from 'react-bootstrap'
import './TaskAspectCard.css'

export default function TaskAspectCard({cardTitle, cardContent, iconName}) {
    return (
        <>
            <Card className='border-left-primary task-aspect-card'>
                <Card.Body>
                    <Container>
                        <Row>
                            <Col>
                                <div className="card-title">{cardTitle}</div>
                                <div className="card-content">{cardContent}</div>
                            </Col>
                            <Col className="col-auto">
                                <i className={`bi ${iconName} me-2`}></i>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </>
    )
}
