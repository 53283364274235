import React, { useEffect, useState, useRef } from 'react'
import { Table } from 'react-bootstrap'
import TaskModal from './TaskModal'
import { fetchJsonFromPublicFolder, fetchTaskBasicInfo, fetchWorkerRecommendationTasks } from '../utils/fetchData'
import { toMostFixedDigits, toMostFixedDigitsOrHyphen } from '../utils/mathUtils'
import { ColorLabel } from './ColorLabel'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

class RecommendationTask {
    constructor(taskId, workerId, startTime, endTime, accuracy, income, recommendReputationGain) {
        this.key = `${taskId}_${workerId}`
        this.taskId = taskId
        this.workerId = workerId
        this.startTime = startTime
        this.endTime = endTime
        this.accuracy = accuracy
        this.income = income
        this.recommendReputationGain = recommendReputationGain
    }
}


export default function WorkerRecommendationTasksTable({ simId, workerId }) {

    // recommendation task list
    const [tasks, setTasks] = useState([])

    // current clicked task
    const [currentTaskId, setCurrentTaskId] = useState(null)

    // function to display the modal
    const showModalFuncRef = useRef(null)

    // column configuration for recommendation task table
    const columns = [
        {
            dataField: 'key',
            hidden: true
        },
        {
            dataField: 'taskId',
            text: 'Task',
            sort: true,
            formatter: (cell, row) => `Task ${cell}`,
            classes: 'task',
            attrs: function callback(cell, row, rowIndex, colIndex) {
                return { 'data-id': cell }
            },
            events: {
                onClick: (evt, column, columnIndex, row, rowIndex) => {
                    console.log(evt.target)
                    setCurrentTaskId(evt.target.dataset['id'])
                    showModalFuncRef.current()
                }
            },
        },
        {
            dataField: 'workerId',
            text: 'Worker',
            sort: true
        }, {
            dataField: 'startTime',
            text: 'Start',
            sort: true
        }, {
            dataField: 'endTime',
            text: 'End'
        }, {
            dataField: 'accuracy',
            text: 'Accuracy',
            formatter: (cell, row) => toMostFixedDigits(cell)
        },
        {
            dataField: 'income',
            headerFormatter: () => <abbr title="Income">INCM</abbr>,
            formatter: (cell, row) => toMostFixedDigitsOrHyphen(cell),
            sort: true,
            text: 'Income'
        }, {
            dataField: 'recommendReputationGain',
            headerFormatter: () => <abbr title="Referral Reputation Gain">RRG</abbr>,
            formatter: (cell, row) => <ColorLabel num={cell} ></ColorLabel>,
            sort: true,
            text: "Referral Reputation Gain"
        }
    ];

    useEffect(() => {

        setTasks([])

        // container to store new recommend task 
        const newRecommendTaskList = []

        // path for the recommendation tasks data
        fetchWorkerRecommendationTasks(simId, workerId, (recommendationTasks) => {

            recommendationTasks.forEach(recommendationTask => {

                // task content
                fetchTaskBasicInfo(simId, recommendationTask.taskId, (resTask) => {
                    newRecommendTaskList.push(new RecommendationTask(recommendationTask.taskId, recommendationTask.recommendWorker, resTask.startTime,
                        resTask.endTime, resTask.accuracy, recommendationTask.income, recommendationTask.recommendReputationGain))

                    if (newRecommendTaskList.length === recommendationTasks.length) {
                        setTasks(newRecommendTaskList)
                    }
                })
            })
        })

    }, [simId, workerId])

    return (
        <div className='task-table-container'>

            {tasks.length>0 &&
                <div>
                    <h6>Worker Referral Tasks</h6>
                    <BootstrapTable
                        // keyField='taskId'
                        keyField='key'
                        data={tasks}
                        columns={columns}
                        pagination={paginationFactory({
                            sizePerPageList: [7, 10]
                        })}
                        bordered={false}
                        condensed={false}
                        bootstrap4={true}
                        rowClasses={(row, rowIndex) => {
                            return row['recommendReputationGain'] > 0 ? 'success-left-border' : 'danger-left-border'
                        }}
                        classes="left-border-table" />
                    <TaskModal simId={simId} taskId={currentTaskId} showModalFuncRef={showModalFuncRef}></TaskModal>
                </div>
            }
        </div>

    )
}
