import * as React from "react";
import './App.css'
import { Routes, Route, Outlet, Link, useSearchParams, useLocation } from "react-router-dom";
import { useState } from "react";
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import Dashboard from "./components/Dashboard";
import Network from './components/Network'
import Timeline from "./components/Timeline";
import { fetchJson } from "./utils/fetchData";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarController,
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarController
);

export default function App() {

  // available sim ids
  const [simIds, setSimIds] = useState([])

  // current selected sim id
  const [selectedSimId, setSelectedSimeId] = useState(null)

  // search params from url
  const [search, setSearch] = useSearchParams();

  // retrieve all available sim ids
  React.useEffect(() => { fetchJson(process.env.PUBLIC_URL + '/output/records.json', setSimIds) }, [])

  // set the first sim id as default current sim id as soon as all sim ids are retrieved
  React.useEffect(() => {

    if (!simIds.length)
      return

    const simIdToSet = simIds.includes(search.get('simId')) ? search.get('simId') : simIds[0];

    setSelectedSimeId(simIdToSet)


  }, [simIds])

  function selectOnChangeEventHandler(event) { setSelectedSimeId(event.target.value) }

  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout simIds={simIds} currentSimId={selectedSimId} selectOnChangeHandler={selectOnChangeEventHandler} />}>
          <Route path="" element={<Dashboard simId={selectedSimId} />} />
          <Route path="network" element={<Network simId={selectedSimId} />} />
          <Route path="timeline" element={<Timeline simId={selectedSimId} />} />


          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout({ simIds, currentSimId, selectOnChangeHandler }) {



  return (
    <div>
      <div className="nav-side-bar shadow">
        <h2 className="display-3">HACFL</h2>
        <Form.Select onChange={selectOnChangeHandler}>
          {
            simIds.map(simId => <option key={simId} value={simId} selected={currentSimId === simId ? true : false}>{simId}</option>)
          }
        </Form.Select>
        <hr />
        <p className="lead">
          Hierarchical Auctioning in Crowd-based Federated Learning
        </p>
        <Nav variant="pills" className="flex-column">
          <LinkContainer to="/">
            <Nav.Link>Dashboard</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/network">
            <Nav.Link>Network</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/timeline">
            <Nav.Link>Timeline</Nav.Link>
          </LinkContainer>

          {/* <LinkContainer to="/nothing-here">
            <Nav.Link>Nothing Here</Nav.Link>
          </LinkContainer> */}
        </Nav>

      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
