import React from 'react'
import { useEffect, useState } from 'react'
import { useRef } from 'react'
import { Card, Row, Col, Container } from 'react-bootstrap'
import './WorkerAspectCard.css'
import { Doughnut } from 'react-chartjs-2'
import { fetchJsonFromPublicFolder } from '../utils/fetchData'
import './WorkerIncomeCard.css'
import { toMostFixedDigits } from '../utils/mathUtils'


export default function WorkerIncomeCard({ simId, workerId }) {

    const [totalIncome, setTotalIncome] = useState(0)

    const [donutData, setDonutData] = useState({ labels: [], datasets: [] })

    useEffect(() => {

        fetchJsonFromPublicFolder('/output/' + simId + '/Workers/' + workerId + '/BasicInfo.json', (workerInfo) => {
            const newData =
            {
                labels: ['Training', 'Referral'],
                datasets: [
                    {
                        data: [workerInfo['trainIncome'], workerInfo['recommendIncome']],
                        label: "Bidding Rep.",
                        backgroundColor: ["#593196", "#967e31"],
                        // fill: true,
                        // backgroundColor: "#8152c7",
                    }
                ]
            }

            setDonutData(newData)

            setTotalIncome(workerInfo['trainIncome'] + workerInfo['recommendIncome'])
        })


    }, [simId, workerId])

    return (
        <>
            {/* <h6>Worker Incomes</h6> */}
            <Card className='worker-income-card'>
                <Card.Body>
                    <Container>
                        <Row className='d-flex flex-row justify-content-between align-items-center'>
                            <Col xs={4}>
                                <div className="card-title">Total Income</div>
                                <div className="card-content display-6">${toMostFixedDigits(totalIncome, 1)}</div>
                            </Col>
                            <Col xs={8}>
                                <div className='worker-income-graph-container d-flex flex-row align-items-center'>
                                    <Doughnut
                                        data={donutData}
                                        options={
                                            {
                                                cutout: '80%',
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        display: true,
                                                        position: 'right',                                                        
                                                        labels: {
                                                            textAlign: 'left',
                                                            font: {
                                                                size: 10
                                                            }
                                                        }
                                                    },
                                                    // title: {
                                                    //     display: false,
                                                    //     text: 'Chart.js Line Chart',
                                                    // },

                                                },
                                            }
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </>
    )
}
