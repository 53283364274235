import React, { useEffect, useState, useRef } from 'react'
import { Table } from 'react-bootstrap'
import TaskModal from './TaskModal'
import { fetchJsonFromPublicFolder, fetchSimBasicInfo, fetchWorkerBasicInfo } from '../utils/fetchData'
import { toFixedDigitsOrHyphen, toMostFixedDigits, toMostFixedDigitsOrHyphen } from '../utils/mathUtils'
import { ColorLabel } from './ColorLabel'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { createSearchParams, redirect, useNavigate } from "react-router-dom"

class Worker {
    constructor(workerId, trainIncome, recommendIncome, bidReputation, trainReputation, recommendReputation, bidTimes, trainTimes, maxRegretTime, datasetSamplings) {
        this.workerId = workerId
        this.trainIncome = trainIncome
        this.recommendIncome = recommendIncome
        this.bidReputation = bidReputation
        this.trainReputation = trainReputation
        this.recommendReputation = recommendReputation
        this.bidTimes = bidTimes
        this.trainTimes = trainTimes
        this.maxRegretTime = maxRegretTime

        this.totalIncome = this.trainIncome + this.recommendIncome

        this.datasetSamplings = datasetSamplings
    }
}


export default function WorkersTable({ simId }) {

    const navigate = useNavigate()

    // recommendation task list
    const [workers, setWorkers] = useState([])

    // column configuration for recommendation task table
    const columns = [
        {
            dataField: 'workerId',
            text: 'Worker',
            sort: true,
            formatter: (cell, row) => `Worker ${cell}`,
            classes: 'worker',
            attrs: function callback(cell, row, rowIndex, colIndex) {
                return { 'data-id': cell }
            },
            events: {
                onClick: (evt, column, columnIndex, row, rowIndex) => {
                    console.log(evt.target)

                    // const params = { workerId: evt.target.dataset['id'] }

                    const params = { simId: simId, workerId: evt.target.dataset['id'] }


                    navigate({ pathname: "/network", search: `?${createSearchParams(params)}` })

                    // if you remove this line of code, 2 active items will be present in the nav bar
                    // try to fix it correctly later
                    navigate(0);
                }
            },
        },
        {
            dataField: 'totalIncome',
            text: 'Total Income',
            sort: true,
            formatter: (cell, row) => toMostFixedDigits(cell, 1)
        },
        {
            dataField: 'trainIncome',
            text: 'Train Income',
            sort: true,
            formatter: (cell, row) => toMostFixedDigits(cell, 1)
        }, {
            dataField: 'recommendIncome',
            text: 'Recommend Income',
            sort: true,
            formatter: (cell, row) => toMostFixedDigits(cell, 1)
        }, {
            dataField: 'bidReputation',
            text: 'Bid Success Ratio',
            sort: true,
            formatter: (cell, row) => toMostFixedDigits(cell)
        }, {
            dataField: 'trainReputation',
            text: 'Train Reputation',
            sort: true,
            formatter: (cell, row) => toMostFixedDigits(cell)
        },
        {
            dataField: 'recommendReputation',
            formatter: (cell, row) => toMostFixedDigitsOrHyphen(cell),
            sort: true,
            text: 'Referral Reputation'
        }, {
            dataField: 'bidTimes',
            sort: true,
            text: "Bids"
        }, {
            dataField: 'trainTimes',
            sort: true,
            text: "Trains"
        }, {
            dataField: 'maxRegretTime',
            sort: true,
            text: "Max Regret Count"
        }
        // ,{
        //     dataField: 'datasetSamplings',
        //     sort: true,
        //     formatter: (cell, row) => cell? `${cell.map(e=>e.toFixed(1)).join('|')}` : '-',
        //     text: "Data Sampling"
        // }
    ];

    useEffect(() => {

        if (!simId) return

        setWorkers([])

        fetchSimBasicInfo(simId, (simInfo) => {

            const newWorkers = []

            simInfo['workerIds'].forEach((workerId) => {
                fetchWorkerBasicInfo(simId, workerId, (workerInfo) => {
                    newWorkers.push(new Worker(
                        workerId,
                        workerInfo['trainIncome'],
                        workerInfo['recommendIncome'],
                        workerInfo['bidReputation'],
                        workerInfo['trainReputation'],
                        workerInfo['recommendReputation'],
                        workerInfo['bidTimes'],
                        workerInfo['trainTimes'],
                        workerInfo['maxRegretTimes'],
                        workerInfo['datasetSamplings']
                    ))

                    if (newWorkers.length === simInfo['workerIds'].length)
                    {
                        setWorkers(newWorkers)
                    }
                })
            })

        })
    }, [simId])

    return (
            <div className='task-table-container'>
                <h5>Workers Table</h5>
                <hr/>
                <BootstrapTable
                    // keyField='taskId'
                    keyField='workerId'
                    data={workers}
                    columns={columns}
                    hover={true}
                    pagination={paginationFactory({
                        sizePerPageList: [10, 15]
                    })}
                    bordered={false}
                    condensed={true}
                    bootstrap4={true} />
            </div>
        )
}
