import React, { useEffect, useState } from 'react'
import { Chart } from 'react-chartjs-2';
import { fetchSimBasicInfo } from '../utils/fetchData';
import { toMostFixedDigits } from '../utils/mathUtils';
import { createRangeArray } from '../utils/other';
import { Container, Row, Col } from 'react-bootstrap'
import './SocialIncomeGraph.css'


export default function SocialIncomeGraph({ simId }) {

  // ======= private states =======

  const [totalIncome, setTotalIncome] = useState(0)
  const [totalTrainingIncome, setTotalTrainingIncome] = useState(0)
  const [totalRecommendIncome, setTotalRecommendIncome] = useState(0)

  const [chartData, setChartData] = useState({ labels: [], datasets: [] })

  const chartOptions = {
    plugins: {
      title: {
        display: false,
        text: 'Total Incomes',
      },
      legend: {
        display: true,
        position: 'bottom'
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        }
      },
      y: {
        stacked: true,
      },
    },
  }

  // ======= component hooks ========
  useEffect(() => {

    if (!simId)
      return

    fetchSimBasicInfo(simId, (basicInfo) => {

      const totalTimeStamp = basicInfo['sysAccumulatedTrainingIncome'].length

      // construct new data
      const newChartData = {
        labels: createRangeArray(totalTimeStamp),
        datasets: [
          {
            label: 'Accumulated Training Income',
            data: basicInfo['sysAccumulatedTrainingIncome'],
            backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--bs-primary')
          },
          {
            label: 'Accumulated Referral Income',
            data: basicInfo['sysAccumulatedRecommendationIncome'],
            backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')
          },
        ]
      }

      setTotalIncome(basicInfo['sysTotalTrainingIncome'] + basicInfo['sysTotalRecommendationIncome'])

      setTotalTrainingIncome(basicInfo['sysTotalTrainingIncome'])

      setTotalRecommendIncome(basicInfo['sysTotalRecommendationIncome'])

      // set new data
      setChartData(newChartData)
    })

  }, [simId])

  return (
    <div className='social-income-graph-container'>
      <Container fluid={true}>
        <Row>
          <Col>
            <h6>Total Social Income</h6>
            <h2>${toMostFixedDigits(totalIncome, 0)}</h2>
          </Col>
          <Col>
            <h6>Total Training Income</h6>
            <h2>${toMostFixedDigits(totalTrainingIncome, 0)}<span>({toMostFixedDigits(totalTrainingIncome/totalIncome*100, 1)}%)</span></h2>
          </Col>
          <Col>
            <h6>Total Referral Income</h6>
            <h2>${toMostFixedDigits(totalRecommendIncome, 0)}<span>({toMostFixedDigits(totalRecommendIncome/totalIncome*100, 1)}%)</span></h2>
          </Col>
        </Row>
      </Container>

      <div className='accu-social-income-container'>
        <Chart type='bar' data={chartData} options={chartOptions} />
      </div>
    </div>
  );
}
