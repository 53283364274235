// https://github.com/iVis-at-Bilkent/cytoscape.js-fcose#api
export const fcoseLayout = {
    name: 'fcose',
    animate: true,
    animationDuration: 1000,
    animationEasing: 'ease-out',
    edgeElasticity: 0.45,
    fit: true,
    gravity: 0.25,
    gravityCompound: 1,
    gravityRange: 3.8,
    gravityRangeCompound: 1.5,
    idealEdgeLength: 200,
    initialEnergyOnIncremental: "0.3",
    nestingFactor: 0.1,
    nodeDimensionsIncludeLabels: false,
    nodeRepulsion: 4500,
    nodeSeparation: 50,
    numIter: 2500,
    packComponents: true,
    padding: 30,
    piTol: 1e-7,
    quality: "default",
    randomize: true,
    sampleSize: 25,
    samplingType: true,
    step: "all",
    tile: true,
    tilingPaddingHorizontal: 10,
    tilingPaddingVertical: 10,
    uniformNodeDimensions: false
}

// https://js.cytoscape.org/#layouts/concentric
export const concentricLayout={
    name: 'concentric',
    concentric: function (node) { // returns numeric value for each node, placing higher nodes in levels towards the centre
        return node.outdegree();
    },
    levelWidth: function (nodes) { // the variation of concentric values in each level
        return nodes.maxDegree() / 20;
    },
    directed: false,
    padding: 20,
    avoidOverlap: true,
    minNodeSpacing: 10,
}