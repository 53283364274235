export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export function toMostFixedDigits(num, digits = 3) {
    if(!parseFloat(num))
        return num

    return parseFloat(num.toFixed(digits))
}

export function toMostFixedDigitsOrHyphen(num, digits = 3) {
    return num ? toMostFixedDigits(num, digits) : '-'
}

export function toFixedDigitsOrHyphen(num, digits = 3) {
    return num ? num.toFixed(digits) : '-'
}