import React from 'react'
import './DashboardCard.css'
import Spinner from 'react-bootstrap/Spinner';

export default function DashboardCard({ title, content }) {
    return (
        <div className='dashboard-card-wrapper'>
            <div className='dashboard-card-container'>
                <h6 className='dashboard-card-title'>{title}</h6>
                <h2 className='dashboard-card-content'>{content}</h2>
            </div>
        </div>
    )
}
