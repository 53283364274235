import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Table, Container, Card } from 'react-bootstrap'
import TaskAspectCard from './TaskAspectCard'
import { fetchJson, fetchJsonFromPublicFolder, fetchTaskBasicInfo, fetchTaskInvolvedAgents, fetchTaskNetwork, fetchWorkerBasicInfo } from '../utils/fetchData'
import Cytoscape from 'cytoscape'
import './TaskDetails.css'
import { getRandomArbitrary, getRandomInt, toMostFixedDigits } from '../utils/mathUtils'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cssVars from 'cytoscape-css-variables';
Cytoscape.use(cssVars);

class InvolvedAgent {
    constructor(agentId, agentType, rankingScore, bidReputation, bidPrice, regretTimes, currentTask, datasetSamplings) {
        this.agentId = agentId
        this.agentType = agentType
        this.rankingScore = rankingScore
        this.bidReputation = bidReputation
        this.bidPrice = bidPrice
        this.regretTimes = regretTimes
        this.currentTask = currentTask
        this.datasetSamplings = datasetSamplings
    }
}

export default function TaskDetails({ simId, taskId }) {

    const [basicInfo, setBasicInfo] = useState({ 'accuracy': 0, 'cost': 0, 'budget': 0, 'workers': 0, 'bidders': 0 })
    const [involvedAgents, setInvolvedAgents] = useState([])
    const [networkElements, setNetworkElements] = useState([])
    const [cytoGraphObj, setCytoGraphObj] = useState(null)

    const taskCytoRef = useRef(null)

    const bootstrapTableRef = useRef(null)

    // column configuration for involved agents table
    const columns = [
        {
            dataField: 'agentId',
            text: 'Worker',
            sort: true,
            formatter: (cell, row) => `Worker ${cell}`
        },
        {
            dataField: 'rankingScore',
            text: 'Ranking Score',
            sort: true,
            formatter: (cell, row) => toMostFixedDigits(cell)
        },
        {
            dataField: 'bidReputation',
            sort: true,
            text: 'Bid Reputation',
            formatter: (cell, row) => toMostFixedDigits(cell)
        },
        {
            dataField: 'bidPrice',
            sort: true,
            text: 'Bid Price',
            formatter: (cell, row) => toMostFixedDigits(cell)
        },
        {
            dataField: 'regretTimes',
            sort: true,
            text: 'Regret Times',
        },
        {
            dataField: 'currentTask',
            formatter: (cell, row) => cell !== -1 ? `Task ${cell}` : '-',
            sort: true,
            text: 'Current Task'
        },
        // {
        //     dataField: 'datasetSamplings',
        //     formatter: (cell, row) => cell !== -1 ? `${cell.join(',')}` : '-',
        //     sort: true,
        //     text: 'Dataset'
        // }
    ];


    const drawGraph = (elems) => {

        // do not proceed if either elements
        if (!(elems))
            return

        // draw the graph
        const newCytoGraphObj = Cytoscape({
            container: taskCytoRef.current,
            boxSelectionEnabled: false,
            autounselectify: true,
            zoomingEnabled: true,
            elements: elems,
            style: [
                {
                    "selector": "edge",
                    "style": {
                        "width": 1,
                        "curveStyle": "straight",
                        "opacity": 0.9
                    }
                },
                {
                    "selector": "node",
                    "style": {
                        "label": "data(label)",
                        "fontSize": 12,
                        "width": 30,
                        "height": 30
                    }
                },
                {
                    "selector": "node[type=\"publisher\"]",
                    "style": {

                        "background-color": "#593196"
                    }
                },
                {
                    "selector": "node[type=\"worker\"]",
                    "style": {
                        // "background-color": getComputedStyle(document.documentElement).getPropertyValue('--custom-success')
                        "background-color": getComputedStyle(document.documentElement).getPropertyValue('--bs-success'),
                        opacity: getComputedStyle(document.documentElement).getPropertyValue('--custom-dim-opacity'),
                    }
                },
                {
                    "selector": "node[type=\"bidder\"]",
                    "style": {
                        "background-color": getComputedStyle(document.documentElement).getPropertyValue('--bs-warning'),
                        opacity: getComputedStyle(document.documentElement).getPropertyValue('--custom-dim-opacity'),
                    }
                },
                {
                    "selector": "node[type=\"engaged\"]",
                    "style": {
                        "background-color": getComputedStyle(document.documentElement).getPropertyValue('--bs-danger'),
                        opacity: getComputedStyle(document.documentElement).getPropertyValue('--custom-dim-opacity'),
                    }
                },
                {
                    "selector": "node.highlight",
                    "style": {
                        "border-width": 5,
                        "border-color": "#009cdc",
                        "border-opacity": 0.5,
                        "width": 60,
                        "height": 60,
                        "fontSize": 32,
                        opacity: 0.8
                    }
                }
            ],
            layout: {
                name: 'breadthfirst',
                roots: 'node[type="publisher"]'
            }
        });

        // generate events
        newCytoGraphObj.on('tap', 'node', function (evt) {
            var node = evt.target;
            // console.log('tapped ' + node.id() + ', out degree:' + node.outdegree());
        });

        setCytoGraphObj(newCytoGraphObj)
    }

    // fetch element data
    useEffect(() => {

        fetchTaskBasicInfo(simId, taskId, (taskInfo) => {
            setBasicInfo(taskInfo)
        })

        fetchTaskNetwork(simId, taskId, (newELements) => {
            setNetworkElements(newELements)
        })

        fetchTaskInvolvedAgents(simId, taskId, (agents) => {

            const involvedAgents = []

            setInvolvedAgents([])

            agents.forEach(a => {
                fetchWorkerBasicInfo(simId, a.agentId, worker =>{
                    involvedAgents.push(new InvolvedAgent(a.agentId, a.type, a.rankingScore, a.bidReputation, a.bidPrice, a.regretTimes, a.currentTask, worker.datasetSamplings))

                    if(involvedAgents.length === agents.length)
                        setInvolvedAgents(involvedAgents)
                })
            })

            // agents.sort(function (a, b) { return a.agentId - b.agentId })
            // setInvolvedAgents(agents.map(a => new InvolvedAgent(a.agentId, a.type, a.rankingScore, a.bidReputation, a.bidPrice, a.regretTimes, a.currentTask)))
        })

    }, [simId, taskId]);

    // listen to change of elements and styles
    useEffect(() => drawGraph(networkElements), [networkElements]);

    return (
        <div className='task-details-container'>
            <Container fluid={true}>
                <Row>
                    <Col><TaskAspectCard cardTitle='Model Accuracy' cardContent={toMostFixedDigits(basicInfo.accuracy)} iconName='bi-bullseye'></TaskAspectCard></Col>
                    <Col><TaskAspectCard cardTitle='Cost/Budget' cardContent={`${toMostFixedDigits(basicInfo.cost)}/${basicInfo.budget}`} iconName='bi-currency-dollar'></TaskAspectCard></Col>
                    <Col><TaskAspectCard cardTitle='Workers/Bidders' cardContent={`${basicInfo.workers}/${basicInfo.bidders}`} iconName='bi-people-fill'></TaskAspectCard></Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body className="task-network-graph-wrapper">
                                <h5>Message Broadcasting Flow</h5>
                                <div id='task-network-graph' ref={taskCytoRef}></div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body className='task-related-workers-wrapper'>
                                <h5>Related Workers</h5>
                                <BootstrapTable
                                    ref={bootstrapTableRef}
                                    keyField='agentId'
                                    data={involvedAgents}
                                    columns={columns}

                                    //https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/pagination-props.html
                                    pagination={paginationFactory({
                                        // sizePerPageList: [7, 10],
                                        sizePerPage: 15,
                                        hideSizePerPage: true
                                    })}
                                    hover={true}
                                    bordered={false}
                                    condensed={false}
                                    bootstrap4={true}
                                    rowClasses={(row, rowIndex) => {
                                        return row['currentTask'] !== -1 ? 'danger-left-border' :
                                            row['agentType'] === 'worker' ? 'success-left-border' :
                                                row['agentType'] === 'bidder' ? 'warning-left-border' : ''
                                    }}
                                    classes="left-circle-table"
                                    selectRow={{
                                        mode: 'checkbox',
                                        hideSelectAll: true,
                                        onSelect: (row, isSelect, rowIndex, e) => {

                                            if (isSelect)
                                                cytoGraphObj.$id(row['agentId']).addClass('highlight')
                                            else
                                                cytoGraphObj.$id(row['agentId']).removeClass('highlight')
                                        }
                                    }}
                                    rowEvents={
                                        {
                                            onMouseEnter: (e, row, rowIndex) => {
                                                cytoGraphObj.$id(row['agentId']).addClass('highlight')
                                            },
                                            onMouseLeave:
                                            (e, row, rowIndex) => {
                                                if(!bootstrapTableRef.current.selectionContext.selected.includes(row['agentId']))
                                                    cytoGraphObj.$id(row['agentId']).removeClass('highlight')
                                            },
                                        }
                                    } 
                                    />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
