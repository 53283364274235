import React, { useEffect, useState, useRef } from 'react'
import { Table } from 'react-bootstrap'
import TaskModal from './TaskModal'
import { fetchJsonFromPublicFolder, fetchSimBasicInfo, fetchTaskBasicInfo, fetchWorkerBasicInfo } from '../utils/fetchData'
import { toMostFixedDigits, toMostFixedDigitsOrHyphen } from '../utils/mathUtils'
import { ColorLabel } from './ColorLabel'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

class Task {
    constructor(taskId, accuracy, startTime, endTime, cost, budget, bidders, workers, payToWorker, payToRecommender) {
        this.taskId = taskId
        this.accuracy = accuracy
        this.startTime = startTime
        this.endTime = endTime
        this.cost = cost
        this.budget = budget
        this.bidders = bidders
        this.workers = workers
        this.payToWorker = payToWorker
        this.payToRecommender = payToRecommender
    }
}


export default function TasksTable({ simId }) {

    // recommendation task list
    const [tasks, setTasks] = useState([])

    // current clicked task
    const [currentTaskId, setCurrentTaskId] = useState(null)

    // function to display the modal
    const showModalFuncRef = useRef(null)

    // column configuration for recommendation task table
    const columns = [
        {
            dataField: 'taskId',
            text: 'Task',
            sort: true,
            formatter: (cell, row) => `Task ${cell}`,
            classes: 'task',
            attrs: function callback(cell, row, rowIndex, colIndex) {
                return { 'data-id': cell }
            },
            events: {
                onClick: (evt, column, columnIndex, row, rowIndex) => {
                    console.log(evt.target)
                    setCurrentTaskId(evt.target.dataset['id'])
                    showModalFuncRef.current()
                }
            },
        },
        {
            dataField: 'accuracy',
            text: 'Accuracy',
            sort: true,
            formatter: (cell, row) => toMostFixedDigits(cell)
        }, {
            dataField: 'startTime',
            text: 'Start',
            sort: true
        }, {
            dataField: 'endTime',
            text: 'End',
            sort: true,
        }, {
            dataField: 'cost',
            text: 'Cost',
            sort: true,
            formatter: (cell, row) => toMostFixedDigits(cell, 1)
        },
        {
            dataField: 'budget',
            formatter: (cell, row) => toMostFixedDigitsOrHyphen(cell, 1),
            sort: true,
            text: 'Budget'
        }, {
            dataField: 'bidders',
            sort: true,
            text: "Bidders"
        }, {
            dataField: 'workers',
            sort: true,
            text: "Workers"
        }, {
            dataField: 'payToRecommender',
            sort: true,
            text: "Payment To Referrals",
            formatter: (cell, row) => toMostFixedDigitsOrHyphen(cell, 1),
        }
    ];

    useEffect(() => {

        if (!simId) return

        // empty the tasks
        setTasks([])

        fetchSimBasicInfo(simId, (simInfo) => {

            const newTasks = []

            simInfo['taskIds'].forEach((taskId) => {
                fetchTaskBasicInfo(simId, taskId, (taskInfo) => {
                    newTasks.push(new Task(
                        taskId,
                        taskInfo['accuracy'],
                        taskInfo['startTime'],
                        taskInfo['endTime'],
                        taskInfo['cost'],
                        taskInfo['budget'],
                        taskInfo['bidders'],
                        taskInfo['workers'],
                        taskInfo['payToWorker'],
                        taskInfo['payToRecommender']
                    ))

                    if (newTasks.length === simInfo['taskIds'].length) {
                        setTasks(newTasks)
                    }
                })
            })

        })
    }, [simId])

    return (
            <div className='task-table-container'>
                <h5>Tasks Table</h5>
                <hr/>
                <BootstrapTable
                    // keyField='taskId'
                    keyField='taskId'
                    data={tasks}
                    columns={columns}
                    pagination={paginationFactory({
                        sizePerPageList: [10, 15]
                    })}
                    hover={true}
                    bordered={false}
                    condensed={true}
                    bootstrap4={true} />
                <TaskModal simId={simId} taskId={currentTaskId} showModalFuncRef={showModalFuncRef}></TaskModal>
            </div>
        )
}
