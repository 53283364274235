import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SocialIncomeGraph from './SocialIncomeGraph'
import KeyParameterTable from './KeyParametersTable'
import WorkersTable from './WorkersTable'
import TasksTable from './TasksTable'
import './Dashboard.css'
import DashboardCard from './DashboardCard'
import { useEffect } from 'react'
import { fetchSimBasicInfo } from '../utils/fetchData'
import { useState } from 'react'
import { toMostFixedDigits } from '../utils/mathUtils'

export default function Dashboard({ simId }) {

    const [taskCount, setTaskCount] = useState(0)
    const [workerCount, setWorkerCount] = useState(0)
    const [taskAccuracyMean, setTaskAccuracyMean] = useState(0)

    useEffect(() => {
        if (!simId)
            return

        fetchSimBasicInfo(simId, (simBasicInfo) => {
            setTaskCount(simBasicInfo['taskIds'].length)
            setWorkerCount(simBasicInfo['workerIds'].length)
            setTaskAccuracyMean(simBasicInfo['sysModelsAverageAccuracy'])
        })

    }, [simId])

    return (
        <div className='dashboard'>
            <h2>Dashboard</h2>
            <hr />
            <Container fluid={true}>
                <Row>
                    <Col md={12} lg={2}>
                        <DashboardCard title={'Tasks'} content={taskCount} ></DashboardCard>
                        <DashboardCard title={'Workers'} content={workerCount} ></DashboardCard>
                        <DashboardCard title={'Task Accuracy Mean'} content={toMostFixedDigits(taskAccuracyMean)} ></DashboardCard>
                    </Col>
                    <Col md={12} lg={10}>
                        <SocialIncomeGraph simId={simId} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} xl={9}>
                        <Row>
                            <WorkersTable simId={simId} />
                        </Row>
                        <Row>
                            <TasksTable simId={simId} />
                        </Row>
                    </Col>
                    <Col lg={12} xl={3}>
                        <KeyParameterTable simId={simId} />
                    </Col>
                </Row>
            </Container>
        </div>

    )
}
