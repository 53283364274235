import React, {useEffect, useState} from 'react'
import { Modal } from 'react-bootstrap'
import TaskDetails from './TaskDetails'

export default function TaskModal({simId, taskId, showModalFuncRef}) {
    const [show, setShow] = useState(false)

    const closeModal = () => setShow(false);
    const showModal = () => setShow(true);

    useEffect(() => {
        showModalFuncRef.current = showModal
    }, [])    

    return (
            <Modal show={show} onHide={closeModal} fullscreen={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Task {taskId}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TaskDetails simId={simId} taskId={taskId}></TaskDetails>
                </Modal.Body>
            </Modal>
    )
}
