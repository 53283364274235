import React, { useState, useEffect } from 'react'
import Cytoscape from 'cytoscape';
import cola from 'cytoscape-cola';
import fcose from 'cytoscape-fcose';
import cise from 'cytoscape-cise';
import elk from 'cytoscape-elk';
import spread from 'cytoscape-spread'
import euler from 'cytoscape-euler'

import { concentricLayout, fcoseLayout } from '../utils/cytoscapeLayouts';
import { useSearchParams } from 'react-router-dom';


import './Network.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import { fetchJson, fetchJsonFromPublicFolder, fetchSimNetwork, fetchWorkerBasicInfo } from '../utils/fetchData'

import { Card, Container, Row, Col, Stack, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import CardHeader from 'react-bootstrap/esm/CardHeader';

import WorkerPublishingTasksTable from './WorkerPublishingTasksTable';
import WorkerBiddingTasksTable from './WorkerBiddingTasksTable'
import WorkerRecommendationTasksTable from './WorkerRecommendationTasksTable'
import WorkerReputationsLineChart from './WorkerReputationsLineChart'
import WorkerIncomeCard from './WorkerIncomeCard'

// https://js.cytoscape.org/#layouts
// Builtin layouts: random, grid, circle, concentric, breadthfirst, cose 
// https://js.cytoscape.org/#extensions/layout-extensions
// layout extensions: cola, avsdf, cise, cose-bilkent, cosep, d3-force, dagre
Cytoscape.use(cola)
Cytoscape.use(fcose)
Cytoscape.use(cise)
Cytoscape.use(elk)
Cytoscape.use(spread)
Cytoscape.use(euler)


function Network({ simId }) {
    const [elements, setElements] = useState(null)
    const [cardTitle, setCardTitle] = useState(null)
    const [cardDesc, setCardDesc] = useState(null)
    const [currentNode, setCurrentNode] = useState(null)

    const [search, setSearch] = useSearchParams();

    const cytoRef = React.useRef(null)
    const cardContainerRef = React.useRef(null)
    const workerIdFilterBoxRef = React.useRef(null)

    // ======= private behaviors =======

    /**
     * highlight worker node based on filter box value
     */
    function highlightWorker() {

        // de-highlight all workers
        cytoRef.current._cyreg.cy.nodes().removeClass('highlighted')

        // if there is a value in worker id filter box, try to highlight workers based on filter box value
        if (workerIdFilterBoxRef.current.value.length !== 0)
            cytoRef.current._cyreg.cy.filter(`node[id*="${workerIdFilterBoxRef.current.value}"]`).addClass('highlighted')
    }

    function closeWorkerDetails() {
        cytoRef.current._cyreg.cy.nodes().removeClass('selected')

        cardContainerRef.current.className = 'hide'
    }

    const drawGraph = (elems) => {

        // do not proceed if elements is null
        if (!(elems))
            return

        // draw the graph
        const cytoGraph = Cytoscape({
            container: cytoRef.current,
            boxSelectionEnabled: false,
            autounselectify: true,
            zoomingEnabled: true,
            elements: elems,
            style: [
                {
                    "selector": "edge",
                    "style": {
                        "width": 1,
                        "curveStyle": "straight",
                        "opacity": 0.2
                    }
                },
                {
                    "selector": "node",
                    "style": {
                        "label": "data(label)",
                        "fontSize": 0,
                        "background-color": getComputedStyle(document.documentElement).getPropertyValue('--custom-green')
                    }
                },
                {
                    "selector": "node.highlighted",
                    "style": {
                        "border-width": 5,
                        "border-color": getComputedStyle(document.documentElement).getPropertyValue('--bs-orange'),
                        "border-opacity": 0.5
                    }
                },
                {
                    "selector": "node.selected",
                    "style": {
                        "border-width": 5,
                        "border-color": "#967e31",
                        "border-opacity": 0.5
                    }
                },
                {
                    "selector": "node[type=\"publisher\"]",
                    "style": {
                        "background-color": getComputedStyle(document.documentElement).getPropertyValue('--bs-primary')
                    }
                }
            ],
            // layout: {
            //     name: 'breadthfirst', // fcoseLayout, breadthfirst
            //     roots: 'node[type="publisher"]'
            // },
            layout: fcoseLayout,
            ready: function () {

                const maxOutDegree = this.nodes().maxOutdegree()
                const minOutDegree = this.nodes().minOutdegree()

                const minSize = 7
                const maxSize = 30

                this.nodes().forEach(function (node) {
                    let size = parseInt((node.outdegree() - minOutDegree) / (maxOutDegree - minOutDegree) * (maxSize - minSize)) + minSize;

                    node.css("width", size);
                    node.css("height", size);

                    node.css("background-opacity", Math.max(node.data()['trainIncomePercentage'], 0.2));

                });
            }
        });

        // generate events
        cytoGraph.on('tap', 'node', function (evt) {

            evt.cy.nodes().forEach(n => { n.removeClass('selected') })

            var node = evt.target;

            console.log(node.data());

            node.addClass('selected')

            // show card
            cardContainerRef.current.className = 'show'

            // set card title
            setCardTitle(`Worker ${node.data()['id']}`)

            // show card content
            setCurrentNode(node)


            fetchWorkerBasicInfo(simId, node.data()['id'], (workerInfo) => {
                setCardDesc({ __html: `bids <b>${workerInfo['bidTimes']}</b> times, trains <b>${workerInfo['trainTimes']}</b> times` })
                // , data distribution: ${workerInfo['datasetSamplings']}
            })
        });

        // highlight worker if search box is not empty
        highlightWorker()
    }

    // ======= component hooks =======
    // An empty dependency array makes sure useEffect run only once when the component is mounted.
    // useEffects does the job of componentDidMount, componentDidUpdate, componentWillUpdate combined.
    // https://blog.bitsrc.io/fetching-data-in-react-using-hooks-c6fdd71cb24a


    useEffect(() => {
        if (!simId)
            return

        // fetch element data
        fetchSimNetwork(simId, setElements)

        // hide card
        cardContainerRef.current.className = 'hide'

    }, [simId]);

    // listen to change of elements
    useEffect(() => drawGraph(elements), [elements]);

    return (
        <div id='network-container'>
            <div id="agent-search-box">
                <Stack direction="horizontal" gap={3}>
                    <Form.Control ref={workerIdFilterBoxRef} defaultValue={search.get('workerId')} placeholder="Search worker here..." onKeyUp={highlightWorker} />
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="tooltip">
                                <ul>
                                    <li>Size: #connection</li>
                                    <li>Opacity: training income</li>
                                    <li><div className='circle purple-circle'></div><label>: publisher and worker</label></li>
                                    <li><div className='circle green-circle'></div><label>: pure worker</label></li>
                                </ul>
                            </Tooltip>
                        }
                    >
                        <i className={`bi bi-info-circle me-2`}></i>
                    </OverlayTrigger>
                </Stack>
            </div>
            <div id="agent-detail-card-container" className='hide' ref={cardContainerRef}>
                <Card>
                    <Card.Header>
                        <div>
                            <h2 id="card-title" className='capitalize-first-letter'>{cardTitle}</h2>
                            <p className='card-desc' dangerouslySetInnerHTML={cardDesc}></p>
                        </div>
                        <i id="card-close-icon" className='bi bi-x-lg' style={{ 'float': 'right' }} onClick={closeWorkerDetails}></i>
                    </Card.Header>
                    <Card.Body>
                        {
                            currentNode &&
                            (
                                <>
                                    <Container className="publisher-cards-container">
                                        <Row>
                                            <WorkerIncomeCard simId={simId} workerId={currentNode.data()['id']}></WorkerIncomeCard>
                                        </Row>
                                        <Row>
                                            <WorkerReputationsLineChart simId={simId} workerId={currentNode.data()['id']}></WorkerReputationsLineChart>
                                        </Row>
                                    </Container>
                                    <WorkerPublishingTasksTable simId={simId} workerId={currentNode.data()['id']}></WorkerPublishingTasksTable>
                                    <WorkerBiddingTasksTable simId={simId} workerId={currentNode.data()['id']}></WorkerBiddingTasksTable>
                                    <WorkerRecommendationTasksTable simId={simId} workerId={currentNode.data()['id']}></WorkerRecommendationTasksTable>
                                </>
                            )
                        }
                    </Card.Body>
                </Card>
            </div>
            <div id='network-graph' ref={cytoRef}></div>
        </div>
    )
}

export default Network
